import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API_BASE_URL, STATIONS_ENDPOINT, STATIONS_RECOMENDED_ENDPOINT, COLLECTIVES_ENDPOINT, BROADCASTS_ENDPOINT, STATIONS_SEARCH_ENDPOINT } from '../services/services';

const fetchStations = async () => {
    const response = await axios.get(`${API_BASE_URL}${STATIONS_ENDPOINT}`);
    return response.data;
};

const fetchStationsRecomended = async () => {
    const response = await axios.get(`${API_BASE_URL}${STATIONS_RECOMENDED_ENDPOINT}`);
    return response.data;
};

const fetchStationsSearch = async () => {
    const response = await axios.get(`${API_BASE_URL}${STATIONS_SEARCH_ENDPOINT}`);
    return response.data;
};

const fetchStation = async (stationSlug) => {
    const response = await axios.get(`${API_BASE_URL}${STATIONS_ENDPOINT}/${stationSlug}`);
    return response.data;
};

const fetchCollectives = async (stationId) => {
    const response = await axios.get(`${API_BASE_URL}${COLLECTIVES_ENDPOINT}/${stationId}`);
    return response.data;
};

const fetchBroadcasts = async (stationId, timeZone) => {
    const headers = { 'x-timezone': timeZone };
    const response = await axios.get(`${API_BASE_URL}${BROADCASTS_ENDPOINT}/${stationId}`, { headers });
    return response.data;
};

export const useStations = () => {
    return useQuery({
        queryKey: ['stations'],
        queryFn: fetchStations
    });
};

export const useStationsRecomended = () => {
    return useQuery({
        queryKey: ['stations'],
        queryFn: fetchStationsRecomended
    });
};

export const useStationsSearch = () => {
    return useQuery({
        queryKey: ['stationsSearch'],
        queryFn: fetchStationsSearch
    });
};

export const useStation = (stationSlug) => {
    return useQuery({
        queryKey: ['station', stationSlug],
        queryFn: () => fetchStation(stationSlug),
        enabled: !!stationSlug,
    });
};

export const useCollectives = (stationId) => {
    return useQuery({
        queryKey: ['collectives', stationId],
        queryFn: () => fetchCollectives(stationId),
        enabled: !!stationId,
    });
};

export const useBroadcasts = (stationId, timeZone) => {
    return useQuery({
        queryKey: ['broadcasts', stationId],
        queryFn: () => fetchBroadcasts(stationId, timeZone),
        enabled: !!stationId,
    });
};