import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const Share = ({ url, section, title, type }) => {

    const location = useLocation();

    const isStationsRoute = () => {
        return location.pathname === ('/red-altavoz' || 'altavoz-en-vivo');
    };

    const iconClassName = isStationsRoute() ? 'text-white' : 'text-custom';

    const [facebookShare, setFacebookShare] = useState(false);
    const [twitterShare, setTwitterShare] = useState(false);
    const [whatsappShare, setWhatsappShare] = useState(false);

    const handleShareSocialMedia = (socialMedia) => {
        const currentUrl = url;
        let shareUrl = '';
        const message = encodeURIComponent(`${type !== 'live' ? 'Escucha' : 'Ve'} ${section} ${title} 📢 a través de Altavoz Radio 📻 #TomaElAltavoz`);

        switch (socialMedia) {
            case 'Facebook':
                shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}&quote=${message}`;
                setFacebookShare(true);
                setTimeout(() => {
                    setFacebookShare(false);
                }, 2000);
                break;
            case 'Twitter':
                shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}&text=${message}`;
                setTwitterShare(true);
                setTimeout(() => {
                    setTwitterShare(false);
                }, 2000);
                break;
            case 'WhatsApp':
                shareUrl = `https://api.whatsapp.com/send?text=${message}%20${encodeURIComponent(currentUrl)}`;
                setWhatsappShare(true);
                setTimeout(() => {
                    setWhatsappShare(false);
                }, 2000);
                break;
            default:
                return;
        }

        window.open(shareUrl, '_blank');
    };

    return (
        <>
            <div className="dropdown dropdown-top">
                <div tabIndex={0} role="button" className={`hover:text-orange-600 ${iconClassName}`} aria-label="share">
                    <i className="fa-solid fa-share-nodes"></i>
                </div>
                <div tabIndex={0} className="grid bg-white rounded-full shadow-2xl dropdown-content place-self-center">
                    <button className="btn btn-ghost mask mask-circle text-custom hover:bg-custom hover:text-white" aria-label="facebook" onClick={() => handleShareSocialMedia('Facebook')}>
                        {facebookShare ? (
                            <span className="loading loading-ring loading-sm"></span>
                        ) : (
                            <i className="fa-brands fa-facebook-f fa-lg"></i>
                        )}
                    </button>
                    <button className="btn btn-ghost mask mask-circle text-custom hover:bg-custom hover:text-white" aria-label="twitter" onClick={() => handleShareSocialMedia('Twitter')}>
                        {twitterShare ? (
                            <span className="loading loading-ring loading-sm"></span>
                        ) : (
                            <i className="fa-brands fa-x-twitter"></i>
                        )}
                    </button>
                    <button className="btn btn-ghost mask mask-circle text-custom hover:bg-custom hover:text-white" aria-label="whatsapp" onClick={() => handleShareSocialMedia('WhatsApp')}>
                        {whatsappShare ? (
                            <span className="loading loading-ring loading-sm"></span>
                        ) : (
                            <i className="fa-brands fa-whatsapp fa-lg"></i>
                        )}
                    </button>
                </div>
            </div>
        </>
    )
}

export default Share
