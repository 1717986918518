import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

const Copy = ({ url }) => {

    const location = useLocation();

    const isStationsRoute = () => {
        return location.pathname === ('/red-altavoz' || '/altavoz-en-vivo');
    };

    const iconClassName = isStationsRoute() ? 'text-white' : 'text-custom';

    const [copied, setCopied] = useState(false);

    const handleCopyUrl = () => {
        const currentUrl = url;

        navigator.clipboard.writeText(currentUrl)
            .then(() => {
                setCopied(true);
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: false,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    }
                });
                Toast.fire({
                    icon: "success",
                    title: "Link copiado al portapapeles"
                });
                setTimeout(() => {
                    setCopied(false);
                }, 600);
            })
            .catch(() => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: false,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    }
                });
                Toast.fire({
                    icon: "error",
                    title: "Error al copiar el link al portapapeles"
                });
            });
    };

    return (
        <button className={`hover:text-orange-600 ${iconClassName}`} aria-label="copy" onClick={handleCopyUrl}>
            {copied ? (
                <>
                    <i className="fa-solid fa-check"></i>
                </>
            ) : (
                <i className="fa-solid fa-link"></i>
            )}
        </button>
    )
}

export default Copy
