import React, { useCallback, useEffect, useRef, useState } from 'react'
import Texts from '../components/texts/Texts';
import transition from '../utils/Transition';
import Share from '../components/functions/Share';
import Copy from '../components/functions/Copy';
import { useTransmissions } from '../hooks/useTransmission';
import Loading from '../utils/Loading';
import { CatchReturn } from '../utils/CatchReturn';
import { Link } from 'react-router-dom';
import { API_BASE_URL, URL_CMS } from '../services/services';
import { clickScroll } from '../utils/StartPage';
import axios from 'axios';
import BitmovinPlayer from './BitmovinPlayer';
import Meta from '../components/layouts/Meta';

const Transmission = () => {

    const { data: transmissions, isLoading, error } = useTransmissions();
    const metaRef = useRef(null);
    const transmissionActive = transmissions?.find(transmission => transmission.status === 'active');

    const [transmissionViews, setTransmissionViews] = useState(transmissionActive);

    useEffect(() => {
        const metaHome = {
            name: 'Altavoz En Vivo',
            title: 'Altavoz En Vivo',
            description: 'Transmisión en vivo de Altavoz Radio MX',
            image: 'https://altavozradio.mx/static/media/AltavozRadio.ad4661c40f46abc3ff00.png',
            url: window.location.href
        }

        metaRef.current = metaHome;
    }, []);

    useEffect(() => {
        if (transmissionActive) {
            setTransmissionViews(transmissionActive);
        }
    }, [transmissionActive]);

    const handlePlay = useCallback(() => {
        if (transmissionActive) {
            axios.post(`${API_BASE_URL}/transmissions/increment-views/${transmissionActive.id}`)
                .then(response => {
                    setTransmissionViews(prev => ({
                        ...prev,
                        views: prev.views + 1
                    }));
                })
                .catch(error => {
                });
        }
    }, [transmissionActive]);

    const handlePause = useCallback(() => {
        if (transmissionActive) {
            axios.post(`${API_BASE_URL}/transmissions/decrement-views/${transmissionActive.id}`)
                .then(response => {
                    setTransmissionViews(prev => ({
                        ...prev,
                        views: Math.max(0, prev.views - 1)
                    }));
                })
                .catch(error => {
                });
        }
    }, [transmissionActive]);

    useEffect(() => {
        return () => {
            if (transmissionActive) {
                handlePause();
            }
        };
    }, [transmissionActive, handlePause]);

    if (isLoading) return <Loading />;

    if (error || !transmissions) return <CatchReturn />;

    return (
        <>
            {metaRef.current && <Meta meta={metaRef.current} />}

            {transmissionActive && (
                <>
                    <Texts title={'Altavoz En Vivo'} />

                    <div className="w-full p-4 text-black bg-gray-100 sm:py-16 lg:pl-48 xl:pl-72">

                        <div className="flex flex-col gap-12 lg:flex-row">
                            {transmissionActive && (
                                <div className='flex flex-col flex-none w-full gap-4 lg:w-7/12 place-self-start xl:place-self-center'>
                                    <Link to={`/audioteca/${transmissionActive.program.slug}`} onClick={clickScroll}>
                                        <article className='flex items-center gap-2 group'>
                                            <figure className='relative w-16 h-16 transition-all duration-500 rounded-lg shadow-black group-hover:shadow-2xl group-hover:w-20 group-hover:h-20'>
                                                <img loading='lazy' src={`${URL_CMS}${transmissionActive.program.banner_path}`} alt={transmissionActive.program.name} className="object-cover w-full h-full rounded-lg" />
                                                <div className="absolute inset-0 flex items-center justify-center p-2">
                                                    <img loading='lazy' src={`${URL_CMS}${transmissionActive.program.image_path}`} alt={transmissionActive.program.name} className="object-contain w-full h-full" />
                                                </div>
                                            </figure>
                                            <p className='text-base sm:text-xl'>{transmissionActive.program.name}</p>
                                        </article>
                                    </Link>
                                    <h2 className='text-2xl font-semibold sm:text-4xl'>{transmissionActive.name}</h2>
                                    <div class="relative transition-all duration-300 ease-in-out shadow-lg group hover:shadow-2xl">
                                        {(transmissionActive.transmission_link).endsWith('.m3u8') && (
                                            <BitmovinPlayer
                                                hls={`${transmissionActive.transmission_link}`}
                                                poster={`${URL_CMS}${transmissionActive.program.image_path}`}
                                                title={transmissionActive.name}
                                            />
                                        )}
                                        <div class="absolute top-0 right-0 z-10 flex items-center justify-end gap-4 p-2 transition-all duration-500 rounded-tr-lg rounded-bl-lg opacity-0 group-hover:opacity-100 bg-black/70">
                                            <Share
                                                url={window.location.href} section={"nuestra transmisión en vivo"} title={transmissionActive.name} type='live'
                                            />
                                            <Copy url={window.location.href} />
                                            {/** 
                                            <p className='flex gap-1 text-white'>
                                                <svg width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                                    <path d="M9 10l.01 0" />
                                                    <path d="M15 10l.01 0" />
                                                    <path d="M9.5 15a3.5 3.5 0 0 0 5 0" />
                                                </svg>
                                                {transmissionViews?.views}
                                            </p>
                                            */}
                                        </div>
                                    </div>
                                    <p dangerouslySetInnerHTML={{ __html: transmissionActive.synopsis }} className='mt-8 text-sm font-medium sm:text-lg'></p>
                                </div>
                            )}
                            <div className="flex flex-col w-full gap-8 px-12 lg:px-4 lg:pt-24">
                                {transmissions.map(transmission => (
                                    <Link key={transmission.id} to={`/audioteca/${transmission.program.slug}`} onClick={clickScroll}>
                                        <article className={`w-full group flex gap-4 ${transition}`}>
                                            <div className='place-self-center'>
                                                <figure className='relative w-16 h-16 rounded-lg sm:w-20 sm:h-20 shadow-black group-hover:shadow-2xl'>
                                                    <img loading='lazy' src={`${URL_CMS}${transmission.program.banner_path}`} alt={transmission.program.name} className="object-cover w-full h-full rounded-lg" />

                                                    <div className="absolute inset-0 flex items-center justify-center p-2">
                                                        <img loading='lazy' src={`${URL_CMS}${transmission.program.image_path}`} alt={transmission.program.name} className="object-contain w-full h-full" />
                                                    </div>

                                                    {transmission.status === 'active' && (
                                                        <div className="absolute flex items-center justify-center p-1 m-1 bg-red-500 rounded-md -left-4 -top-4 shadow-black group-hover:shadow-xl">
                                                            <p className='font-medium text-white text-xxs sm:text-xs'>En Vivo</p>
                                                        </div>
                                                    )}
                                                </figure>
                                            </div>

                                            <div className='flex flex-col justify-between gap-2'>
                                                <p className='text-base sm:text-xl'>{transmission.name}</p>
                                                <p className='text-xs sm:text-base'>{transmission.program.name}</p>
                                            </div>
                                        </article>
                                    </Link>
                                ))}
                            </div>
                        </div >
                    </div >
                </>
            )
            }
        </>
    )
}

export default Transmission
