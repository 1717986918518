import React from 'react'

const Loading = () => {
    return (
        <div className="flex items-center justify-center h-screen">
            <span className="loading loading-ring loading-lg text-orange-600"></span>
        </div>
    )
}

export default Loading
