import React from 'react';
import Error from '../assets/404.svg';

const NotFound = () => {
    return (
        <div className="hero min-h-screen">
            <div className="text-center font-bold relative">
                <p className='text-9xl font-black font-Sedgwick'>404</p>
                <br />
                <p className="text-6xl">Página no encontrada</p>
                <br />
                <p className='text-3xl'>Lo sentimos, no se pudo encontrar la página que estás buscando.</p>
                <div className="pt-32 w-full flex justify-center items-center">
                    <a href="/">
                        <p className='p-4 hover:pl-0 w-100 text-5xl bg-custom rounded-xl'>
                            <i class="fa-regular fa-hand-point-left"></i>
                            <span className='mx-6 text-6xl'>Regresar</span>
                        </p>
                    </a>
                </div>
            </div>
            <img src={Error} alt="" />
        </div>
    )
}

export default NotFound
