import React from 'react';

const Texts = ({ title, subtitle, text, maxLength }) => {

    const isTruncated = text?.length > maxLength;
    const truncatedText = isTruncated ? text.slice(0, maxLength) + '...' : text;

    return (
        <>
            {title && (
                <h1 id="Texts" className="m-5 lg:ml-48 xl:ml-72 font-Sedgwick text-2xl sm:text-6xl">{title}</h1>
            )}
            {subtitle && (
                <h2 className="my-8 sm:my-20 p-5 sm:p-8 text-2xl sm:text-6xl border-y-2 border-gray-800">{subtitle}</h2>
            )}
            <p className="text-xs sm:text-sm font-medium" dangerouslySetInnerHTML={{ __html: truncatedText }}></p>
        </>
    );
};

export default Texts;
