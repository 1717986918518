import React, { useEffect, useRef, useState } from 'react';
import { Player } from 'bitmovin-player';
import { PlayerModule as AnalyticsModule } from 'bitmovin-analytics';
import { UIFactory } from 'bitmovin-player/bitmovinplayer-ui';
import { PLAYER_KEY, ANALYTICS_KEY } from '../services/services';
import 'bitmovin-player/bitmovinplayer-ui.css';

Player.addModule(AnalyticsModule);

const BitmovinPlayer = ({ hls, poster, title }) => {

    const [player, setPlayer] = useState(null);

    const playerConfig = {
        key: PLAYER_KEY,
        ui: false,
        analytics: {
            key: ANALYTICS_KEY,
        },
    };

    const playerSource = {
        hls: hls,
        poster: poster,
        title: title,
    };
    const playerDiv = useRef();

    useEffect(() => {
        function setupPlayer() {
            const playerInstance = new Player(playerDiv.current, playerConfig);
            UIFactory.buildDefaultUI(playerInstance);

            playerInstance.load(playerSource).then(() => {
                setPlayer(playerInstance)
            }, () => {
                return null;
            });

            playerInstance.on('paused', () => {
                playerInstance.setVolume(0);
            });

            playerInstance.on('play', () => {
                playerInstance.play();
                playerInstance.setVolume(10);
            })

            playerInstance.on('viewmodechanged', (event) => {
                if (event.from === 'inline' && event.to === 'pictureinpicture') {
                    playerInstance.play();
                    playerInstance.setVolume(50)
                } else if (event.from === 'pictureinpicture' && event.to === 'inline'){
                    playerInstance.pause();
                }
            });
        }

        setupPlayer();

        return () => {
            function destroyPlayer() {
                if (player != null) {
                    player.destroy();
                    setPlayer(null);
                }
            }

            destroyPlayer();
        }
    }, []);

    return (
        <div id='player' ref={playerDiv}></div>
    );
}

export default BitmovinPlayer;