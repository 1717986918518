import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API_BASE_URL, TRANSMISSIONS_ENDPOINT } from '../services/services';

const fetchTransmissions = async () => {
    const response = await axios.get(`${API_BASE_URL}${TRANSMISSIONS_ENDPOINT}`);
    return response.data;
};

export const useTransmissions = () => {
    return useQuery({
        queryKey: ['transmissions'],
        queryFn: fetchTransmissions
    });
};
