import React, { Suspense, lazy } from 'react';
import { HashRouter, Outlet, Route, Routes } from 'react-router-dom';
import './App.css';
import { BarMusicProvider } from './context/BarMusicContext';
import Loading from './utils/Loading';
import NotFound from './pages/NotFound'; 
import Transmission from './pages/Transmission';

const Layout = lazy(() => import('./components/layouts/Layout'));
const Home = lazy(() => import('./pages/Home'));
const Station = lazy(() => import('./pages/Station/Station'));
const StationDetail = lazy(() => import('./pages/Station/StationDetail'));
const Podcast = lazy(() => import('./pages/Podcast/Podcast'));
const PodcastDetail = lazy(() => import('./pages/Podcast/PodcastDetail'));
const PlaylistDetail = lazy(() => import('./pages/Podcast/PlaylistDetail'));
const HistoryDetail = lazy(() => import('./pages/Podcast/HistoryDetail'));
const Music = lazy(() => import('./pages/Music/Music'));
const Program = lazy(() => import('./pages/Audiolibrary/Program'));
const AudiolibraryDetail = lazy(() => import('./pages/Audiolibrary/AudiolibraryDetail'));
const About = lazy(() => import('./pages/About'));
const Announcement = lazy(() => import('./pages/Announcement/Announcement'));
const AnnouncementDetail = lazy(() => import('./pages/Announcement/AnnouncementDetail'));
const BarMusic = lazy(() => import('./players/BarMusic'));

const App = () => {

  const RoutesWithBarMusic = () => (
    <BarMusicProvider>
      <BarMusic />
      <Layout>
        <Outlet />
      </Layout>
    </BarMusicProvider>
  );

  const RoutesWithoutBarMusic = () => (
    <Layout>
      <Outlet />
    </Layout>
  );

  return (
    <div className="scrollbarApp font-Montserrat text-white bg-center bg-cover bg-no-repeat h-screen w-screen overflow-auto">
      <HashRouter basename="/">
        <Suspense fallback={<Loading />}>
          <Routes>

            <Route element={<RoutesWithBarMusic />}>
              <Route path="/" element={<Home />} />
              <Route path="/red-altavoz" element={<Station />} />
              <Route path="/red-altavoz/:stationSlug" element={<StationDetail />} />
              <Route path="/audioteca" element={<Program />} />
              <Route path="/quienes-somos" element={<About />} />
              <Route path="/convocatorias" element={<Announcement />} />
              <Route path="/convocatorias/:announcementSlug" element={<AnnouncementDetail />} />
              <Route path="/altavoz-podcast" element={<Podcast />} />
            </Route>

            <Route element={<RoutesWithoutBarMusic />}>
              <Route path="/altavoz-podcast/:podcastSlug" element={<PodcastDetail />} />
              <Route path="/altavoz-podcast/:podcastSlug/:episodeSlug" element={<PodcastDetail />} />
              <Route path="/playlist/:playlistSlug" element={<PlaylistDetail />} />
              <Route path="/historias-vivas/:historySlug" element={<HistoryDetail />} />
              <Route path="/nuestra-musica" element={<Music />} />
              <Route path="/audioteca/:programSlug" element={<AudiolibraryDetail />} />
              <Route path="/audioteca/:programSlug/:chapterSlug" element={<AudiolibraryDetail />} />
              <Route path="/altavoz-en-vivo" element={<Transmission />}/>
            </Route>

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </HashRouter>
    </div>
  );
}

export default App;
