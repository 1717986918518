import React from 'react'
import { Helmet } from 'react-helmet-async';

const Meta = ({ meta }) => {
    if (!meta) return null;

    return (
        <Helmet>
            <title>{meta.title}</title>
            <meta name="name" content={meta.name} />
            <meta name="description" content={meta.description} />
            {/* Google / Search Engine Tags  */}
            <meta itemprop="name" content="Altavoz Radio MX" />
            <meta itemprop="description" content="AltavozRadioMX, radio en colectivo" />
            <meta itemprop="image" content={meta.image} />
            {/* Etiquetas Open Graph para Facebook */}
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.description} />
            <meta property="og:image" content={meta.image} />
            <meta property="og:image:alt" content={meta.name} />
            <meta property="og:url" content={meta.url} />
            <meta property="og:locale" content="es_MX" />
            <meta property="og:type" content="website" />
            <meta property="fb:app_id" content="853275305027650" />
            {/* Etiquetas Open Graph para Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={meta.title} />
            <meta name="twitter:description" content={meta.description} />
            <meta name="twitter:image" content={meta.image} />
            <meta name="twitter:image:alt" content={meta.name} />
            {/* Etiquetas Open Graph para WhatsApp */}
            <meta property="og:site_name" content={meta.title} />
        </Helmet>
    )
}

export default Meta;
