import React, { createContext, useState, useEffect, useRef, useCallback } from 'react';
import { useStations } from '../hooks/useStation';

const BarMusicContext = createContext();

export const BarMusicProvider = ({ children }) => {
    const { data: stations, isLoading, error } = useStations();
    const [currentStationIndex, setCurrentStationIndex] = useState(() => {
        const savedIndex = localStorage.getItem('currentStationIndex');
        return savedIndex !== null ? parseInt(savedIndex, 10) : null;
    });
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioUrl, setAudioUrl] = useState('');
    const [volume, setVolume] = useState(0.1);
    const audioRef = useRef(null);
    const [fullStation, setFullStation] = useState('');

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume;
        }
    }, [volume]);

    const getStationIndexByUserRegion = useCallback(async () => {
        if (!stations || stations.length === 0) return 0;
        const index = stations.findIndex(station => station.slug === 'web');
        return index !== -1 ? index : 0;
/*
        try {
            const ipPromise = new Promise((resolve, reject) => {
                const pc = new RTCPeerConnection();
                pc.createDataChannel('');
                pc.createOffer()
                    .then(offer => pc.setLocalDescription(offer))
                    .catch(error => reject(error));
                pc.onicecandidate = event => {
                    if (!event || !event.candidate) {
                        return resolve('');
                    }
                    const candidate = event.candidlet allStates = [];
    let currentPage = 1;

    while (true) {
        const response = await axios.get(`${APISTATES}?page=${currentPage}`);
        const data = response.data;

        allStates = [...allStates, ...data.states];

        if (!data.meta.pagination.links.next) {
            break;
        }
        currentPage++;
    }ate.candidate;
                    const ipMatch = candidate.match(/(1?\d{1,3}\.){3}\d{1,3}/);
                    if (ipMatch) {
                        resolve(ipMatch[0]);
                    }
                };
            });
    
            const ip = ipPromise;
    
            const response = await axios.get(`http://ip-api.com/json/${ip}`);
    
            const userRegion = response.data.regionName;
            const userCountry = response.data.country;
    
            if (userCountry === 'Mexico') {
                const matchingStationIndex = stations.findIndex(station => station.state === userRegion && station.priority === 'active');
                return matchingStationIndex !== -1 ? matchingStationIndex : 4;
            } else {
                return 4;
            }
        } catch (error) {
            return 4;
        }
        */
    }, [stations]);

    useEffect(() => {
        if (stations && stations.length > 0) {
            const initializeStation = async () => {
                let index = currentStationIndex;
                if (index === null) {
                    index = await getStationIndexByUserRegion();
                    setCurrentStationIndex(index);
                    localStorage.setItem('currentStationIndex', index);
                }
                setAudioUrl(stations[index]?.station_link);
                const station = stations[index];
                const stationName = `Altavoz ${station?.name}`;
                const fullStationName = station?.station === 0 ? stationName : `${stationName} ${station?.station} FM`;
                setFullStation(fullStationName);
            };
            initializeStation();
        }
    }, [stations, getStationIndexByUserRegion, currentStationIndex]);

    const playAudio = useCallback(() => {
        if (audioRef.current) {
            const playPromise = audioRef.current.play();
            if (playPromise !== undefined) {
                playPromise.then(() => {
                    setIsPlaying(true);
                }).catch(error => {
                    // Handle play error
                });
            }
        }
    }, []);

    useEffect(() => {
        if (audioRef.current && audioUrl) {
            audioRef.current.src = audioUrl;
            audioRef.current.load();
            audioRef.current.addEventListener('canplaythrough', playAudio, { once: true });
        }
    }, [audioUrl, playAudio]);

    const changeStation = (direction) => {
        if (!stations || stations.length === 0) return;
        const newIndex = direction === 'next'
            ? (currentStationIndex + 1) % stations.length
            : (currentStationIndex - 1 + stations.length) % stations.length;
        setCurrentStationIndex(newIndex);
        localStorage.setItem('currentStationIndex', newIndex);
        setAudioUrl(stations[newIndex].station_link);
        const station = stations[newIndex];
        const stationName = `Altavoz ${station.name}`;
        const fullStationName = station.station === 0 ? stationName : `${stationName} ${station.station} FM`;
        setFullStation(fullStationName);
    };

    const togglePlayPause = () => {
        setIsPlaying(prevState => !prevState);
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                playAudio();
            }
        }
    };

    const handleLoadedMetadata = () => {
        playAudio();
    };

    const handleVolumeChange = (e) => {
        setVolume(e.target.value);
    };

    const handleStationClick = (index) => {
        if (currentStationIndex === index) {
            togglePlayPause();
        } else {
            setCurrentStationIndex(index);
            localStorage.setItem('currentStationIndex', index);
            setAudioUrl(stations[index].station_link);
            const station = stations[index];
            const stationName = `Altavoz ${station.name}`;
            const fullStationName = station.station === 0 ? stationName : `${stationName} ${station.station} FM`;
            setFullStation(fullStationName);
        }
    };

    if (isLoading) return null;

    if (error) return null;

    if (!stations) return null;

    const value = {
        stations,
        currentStationIndex,
        isLoading,
        isPlaying,
        audioUrl,
        volume,
        audioRef,
        fullStation,
        getStationIndexByUserRegion,
        changeStation,
        togglePlayPause,
        handleLoadedMetadata,
        handleVolumeChange,
        handleStationClick,
    };

    return <BarMusicContext.Provider value={value}>{children}</BarMusicContext.Provider>;
};

export default BarMusicContext;
